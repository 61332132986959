<template>
  <div>
    <a-modal style="top: 8px" :width="1000" v-model="visible" :footer="null" :title="dialogTitle" :maskClosable="false">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <!--        <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
          <!--          <a-form-model-item label="使用订单号">-->
          <!--            <a-input v-model="searchData.orderCode" placeholder="请输入使用订单号"></a-input>-->
          <!--          </a-form-model-item>-->
          <!--        </a-col>-->
          <!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
          <!--          <a-form-model-item label="交易类型">-->
          <!--            <DictSelect-->
          <!--              field="dealType"-->
          <!--              :value.sync="searchData.dealType"-->
          <!--              style="width: 100%"-->
          <!--              placeholder="请选择交易状态"-->
          <!--            ></DictSelect>-->
          <!--          </a-form-model-item>-->
          <!--        </a-col>-->
          <!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
          <!--          <a-form-model-item label="金额类型">-->
          <!--            <DictSelect-->
          <!--              field="amountType"-->
          <!--              :value.sync="searchData.amountType"-->
          <!--              style="width: 100%"-->
          <!--              placeholder="请选择金额状态"-->
          <!--            ></DictSelect>-->
          <!--          </a-form-model-item>-->
          <!--        </a-col>-->
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="还款时间">
              <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getList()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <a-table
        :loading="tableLoading"
        :columns="columns"
        :data-source="tableData"
        :pagination="page"
        @change="changeTable"
        :rowKey="(record) => record.id"
        bordered
        :maskClosable="false"
      >
        <!--      <span slot="repaymentAmount" slot-scope="text, row">{{row.amountType==1?"":"-"}} {{ text | formatMoney }} </span>-->
        <span slot="repaymentCode" slot-scope="text, row">
          <span @click.stop=""
            ><a href="#" @click="checkHKInfo(row)">{{ text }}</a></span
          >
        </span>
      </a-table>

      <div style="text-align: right; margin-top: 14px">
        <a-button type="default" @click="toCancel">取消</a-button>
        <!--      <a-button type="primary" @click="toSubmit">确认</a-button>-->
      </div>
    </a-modal>
    <a-modal
      style="top: 8px"
      :title="title"
      :footer="null"
      :maskClosable="false"
      :width="1000"
      :visible="HKvisible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-card>
        <a-descriptions
          ><a-descriptions-item label="经销商额度流水号">
            <span @click.stop="">
              <a href="#" @click="checkDistributorLSH(HK_data)">{{ HK_data.limitCode || '-' }}</a>
            </span>
          </a-descriptions-item></a-descriptions
        >
        <a-descriptions>
          <a-descriptions-item label="还款前额度">
            {{ HK_data.beforeRepaymentAmount | formatMoney }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions>
          <a-descriptions-item label="还款额度"> {{ HK_data.repaymentAmount | formatMoney }} </a-descriptions-item>
        </a-descriptions>
        <a-descriptions>
          <a-descriptions-item label="还款后额度"> {{ HK_data.remainingAmount | formatMoney }} </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          ><a-descriptions-item label="还款人">
            {{ HK_data.createUser }}
          </a-descriptions-item></a-descriptions
        >
        <a-descriptions>
          <a-descriptions-item label="还款时间"> {{ HK_data.createTime }} </a-descriptions-item>
        </a-descriptions>
        <a-descriptions>
          <a-descriptions-item label="备注"> {{ HK_data.remark }} </a-descriptions-item>
        </a-descriptions>
      </a-card>

      <div style="text-align: right; margin-top: 14px">
        <a-button type="default" @click="handleCancel">取消</a-button>
      </div>
    </a-modal>
    <FinanceCreditModal ref="FinanceCreditModal" />
  </div>
</template>
<script>
const qs = require('qs')

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'RepaymentDetailed',
  components: {
    FinanceCreditModal: () => import('../components/FinanceCreditModal.vue'),
    // CustomerInfoCheckModal: () => import('../../customer/components/CustomerInfoCheckModal.vue')
  },
  data() {
    return {
      HKvisible: false,
      dialogTitle: '',
      title: '',
      columns: [
        {
          title: '还款单号',
          dataIndex: 'repaymentCode',
          align: 'center',
          key: 'repaymentCode',
          scopedSlots: { customRender: 'repaymentCode' },
        },
        {
          title: '还款额度',
          dataIndex: 'repaymentAmount',
          align: 'center',
          key: 'repaymentAmount',
          // scopedSlots: {customRender: 'repaymentAmount'}
        },
        {
          title: '还款时间',
          dataIndex: 'createTime',
          align: 'center',
          key: 'createTime',
        },
      ],
      visible: false,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      dealerId: 0,
      HK_data: {},
    }
  },
  methods: {
    // 查询
    getList() {
      this.getData()
    },
    // 经销商流水号
    checkDistributorLSH(row) {
      if (!row.limitCode) {
        return false
      }
      const data = {
        orderCode: row.limitCode,
      }
      this.$nextTick(() => {
        console.log(this.$refs.FinanceCreditModal)
        if (this.$refs.FinanceCreditModal) {
          this.$refs.FinanceCreditModal.setRowData(data, 'checkCode')
        }
      })
    },
    // 查看还款详情
    checkHKInfo(row) {
      this.HKvisible = true
      if (!row.repaymentCode) {
        return false
      }
      const res_data = row.repaymentCode
      this.axios.get(`/api/dealer/limit/limitInfoRepaymentDetail/queryByCode/${res_data}`).then((res) => {
        if (res.code === 200) {
          this.HK_data = res.body
          this.title = `还款单【${res.body.repaymentCode}】`
        }
      })
    },
    // 经销商查看还款详情
    checkHKInfoJXS(row) {
      this.HKvisible = true
      if (!row.dealNo) {
        return false
      }
      const res_data = row.dealNo
      this.axios.get(`/api/dealer/limit/limitInfoRepaymentDetail/queryByCode/${res_data}`).then((res) => {
        if (res.code === 200) {
          this.HK_data = res.body
          this.title = `还款单【${res.body.repaymentCode}】`
        }
      })
    },
    // 重置
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.getData()
    },
    setRowDataByCode(row) {
      this.$nextTick(() => {
        this.checkHKInfoJXS(row)
      })
    },
    setRowData(row) {
      this.dialogTitle = '【' + row.name + '】还款记录'
      this.visible = true
      this.limitId = row.id
      this.searchData.startDate = moment().startOf('month').format('YYYY-MM-DD') // 获取当月第一天
      this.searchData.endDate = moment().format('YYYY-MM-DD')
      this.getData()
    },
    handleOk(e) {
      this.HKvisible = false
    },
    handleCancel(e) {
      this.HKvisible = false
    },
    getData() {
      this.tableLoading = true
      let params = {
        limitId: this.limitId,
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
      }
      const postData = Object.assign(params, this.searchData)
      this.axios
        .get(`/api/dealer/limit/limitInfoRepaymentDetail/list?${qs.stringify(postData, { arrayFormat: 'repeat' })}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

    // table分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    toCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-label {
  width: 8.25rem;
  text-align: right;
}
</style>